import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f3f8f54"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "logout"
}
const _hoisted_2 = {
  key: 2,
  class: "delete"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "additional" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_elements = _resolveComponent("modal-elements")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(_ctx.data.content.action)
  }, [
    (_ctx.isAddEdit)
      ? (_openBlock(), _createBlock(_component_modal_elements, {
          key: 0,
          action: _ctx.data.content.action,
          module: _ctx.data.inputs
        }, null, 8, ["action", "module"]))
      : _createCommentVNode("", true),
    (_ctx.isLogout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.messageLogout), 1))
      : _createCommentVNode("", true),
    (_ctx.isMessageModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isRemove)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.messageRemove
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.data.content.message), 1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.additionalMessageRemove), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}