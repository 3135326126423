import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24bb6c53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["value", "type", "placeholder"]
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["value", "data-id", "placeholder"]
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = ["type", "placeholder"]
const _hoisted_7 = ["type", "placeholder"]
const _hoisted_8 = ["type", "placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "drop"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_static = _resolveComponent("modal-static")!
  const _component_modal_static_list = _resolveComponent("modal-static-list")!
  const _component_modal_checkbox = _resolveComponent("modal-checkbox")!
  const _component_modal_replenishment = _resolveComponent("modal-replenishment")!
  const _component_modal_buttons = _resolveComponent("modal-buttons")!
  const _component_modal_quiz = _resolveComponent("modal-quiz")!
  const _component_modal_channel_children = _resolveComponent("modal-channel-children")!
  const _component_modal_channel_list = _resolveComponent("modal-channel-list")!
  const _component_modal_recipient = _resolveComponent("modal-recipient")!
  const _component_modal_quiz_answer = _resolveComponent("modal-quiz-answer")!
  const _component_modal_copy = _resolveComponent("modal-copy")!
  const _component_modal_video = _resolveComponent("modal-video")!
  const _component_modal_tariff_list = _resolveComponent("modal-tariff-list")!
  const _component_modal_payment_list = _resolveComponent("modal-payment-list")!
  const _component_modal_link = _resolveComponent("modal-link")!
  const _component_modal_switcher = _resolveComponent("modal-switcher")!
  const _component_modal_image = _resolveComponent("modal-image")!
  const _component_modal_attachment = _resolveComponent("modal-attachment")!
  const _component_modal_drop = _resolveComponent("modal-drop")!
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _directive_mask = _resolveDirective("mask")!

  return (_ctx.input.type === 'static' && _ctx.input.show)
    ? (_openBlock(), _createBlock(_component_modal_static, {
        key: 0,
        style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
        input: _ctx.input,
        data: _ctx.data
      }, null, 8, ["style", "input", "data"]))
    : (_ctx.input.type === 'static-list' && _ctx.input.show)
      ? (_openBlock(), _createBlock(_component_modal_static_list, {
          key: 1,
          style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
          input: _ctx.input,
          data: _ctx.data
        }, null, 8, ["style", "input", "data"]))
      : (_ctx.input.type === 'checkbox' && _ctx.input.show)
        ? (_openBlock(), _createBlock(_component_modal_checkbox, {
            key: 2,
            style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
            input: _ctx.input,
            data: _ctx.data
          }, null, 8, ["style", "input", "data"]))
        : (_ctx.input.type === 'replenishment' && _ctx.input.show)
          ? (_openBlock(), _createBlock(_component_modal_replenishment, {
              key: 3,
              style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
              input: _ctx.input,
              data: _ctx.data
            }, null, 8, ["style", "input", "data"]))
          : (_ctx.input.type === 'buttons' && _ctx.input.show)
            ? (_openBlock(), _createBlock(_component_modal_buttons, {
                key: 4,
                style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                input: _ctx.input,
                data: _ctx.data
              }, null, 8, ["style", "input", "data"]))
            : (_ctx.input.type === 'quiz' && _ctx.input.show)
              ? (_openBlock(), _createBlock(_component_modal_quiz, {
                  key: 5,
                  style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                  input: _ctx.input,
                  data: _ctx.data
                }, null, 8, ["style", "input", "data"]))
              : (_ctx.input.type === 'channel-children' && _ctx.input.show)
                ? (_openBlock(), _createBlock(_component_modal_channel_children, {
                    key: 6,
                    style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                    input: _ctx.input,
                    data: _ctx.data
                  }, null, 8, ["style", "input", "data"]))
                : (_ctx.input.type === 'channel-list' && _ctx.input.show)
                  ? (_openBlock(), _createBlock(_component_modal_channel_list, {
                      key: 7,
                      style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                      input: _ctx.input,
                      data: _ctx.data
                    }, null, 8, ["style", "input", "data"]))
                  : (_ctx.input.type === 'recipient' && _ctx.input.show)
                    ? (_openBlock(), _createBlock(_component_modal_recipient, {
                        key: 8,
                        style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                        input: _ctx.input,
                        data: _ctx.data
                      }, null, 8, ["style", "input", "data"]))
                    : (_ctx.input.type === 'quiz-answer' && _ctx.input.show)
                      ? (_openBlock(), _createBlock(_component_modal_quiz_answer, {
                          key: 9,
                          style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                          input: _ctx.input,
                          data: _ctx.data
                        }, null, 8, ["style", "input", "data"]))
                      : (_ctx.input.type === 'copy' && _ctx.input.show)
                        ? (_openBlock(), _createBlock(_component_modal_copy, {
                            key: 10,
                            style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                            input: _ctx.input,
                            data: _ctx.data
                          }, null, 8, ["style", "input", "data"]))
                        : (_ctx.input.type === 'video' && _ctx.input.show)
                          ? (_openBlock(), _createBlock(_component_modal_video, {
                              key: 11,
                              style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                              input: _ctx.input,
                              data: _ctx.data
                            }, null, 8, ["style", "input", "data"]))
                          : (_ctx.input.type === 'tariff-list' && _ctx.input.show)
                            ? (_openBlock(), _createBlock(_component_modal_tariff_list, {
                                key: 12,
                                style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                input: _ctx.input,
                                data: _ctx.data
                              }, null, 8, ["style", "input", "data"]))
                            : (_ctx.input.type === 'payment-list' && _ctx.input.show)
                              ? (_openBlock(), _createBlock(_component_modal_payment_list, {
                                  key: 13,
                                  style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                  input: _ctx.input,
                                  data: _ctx.data
                                }, null, 8, ["style", "input", "data"]))
                              : (_ctx.input.type === 'link' && _ctx.input.show)
                                ? (_openBlock(), _createBlock(_component_modal_link, {
                                    key: 14,
                                    style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                    input: _ctx.input
                                  }, null, 8, ["style", "input"]))
                                : (_ctx.input.type === 'switcher' && _ctx.input.show)
                                  ? (_openBlock(), _createBlock(_component_modal_switcher, {
                                      key: 15,
                                      style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                      input: _ctx.input,
                                      data: _ctx.data,
                                      onToggleSwitcher: _ctx.toggleSwitcher
                                    }, null, 8, ["style", "input", "data", "onToggleSwitcher"]))
                                  : (_ctx.input.type === 'image' && _ctx.input.show)
                                    ? (_openBlock(), _createBlock(_component_modal_image, {
                                        key: 16,
                                        style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                        input: _ctx.input,
                                        data: _ctx.data,
                                        onUploadFile: _ctx.uploadFile
                                      }, null, 8, ["style", "input", "data", "onUploadFile"]))
                                    : (_ctx.input.type === 'attachment' && _ctx.input.show)
                                      ? (_openBlock(), _createBlock(_component_modal_attachment, {
                                          key: 17,
                                          style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                          input: _ctx.input,
                                          data: _ctx.data,
                                          onUploadFile: _ctx.uploadFile
                                        }, null, 8, ["style", "input", "data", "onUploadFile"]))
                                      : (_ctx.input.show)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 18,
                                            class: "input flex-column",
                                            style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`)
                                          }, [
                                            (_ctx.input.name)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.input.name), 1))
                                              : _createCommentVNode("", true),
                                            (_ctx.input.type === 'drop' && _ctx.input.show)
                                              ? (_openBlock(), _createBlock(_component_modal_drop, {
                                                  key: 1,
                                                  style: _normalizeStyle(`grid-column: ${_ctx.input.grid}`),
                                                  input: _ctx.input,
                                                  data: _ctx.data,
                                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
                                                }, null, 8, ["style", "input", "data"]))
                                              : (_ctx.input.disabled)
                                                ? (_openBlock(), _createElementBlock("input", {
                                                    key: 2,
                                                    value: _ctx.data[_ctx.input.id],
                                                    type: _ctx.input.type,
                                                    class: _normalizeClass({ error: _ctx.input.error }),
                                                    placeholder: _ctx.input.placeholder,
                                                    disabled: ""
                                                  }, null, 10, _hoisted_2))
                                                : (_ctx.input.type === 'textarea')
                                                  ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                                                      key: 3,
                                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                                                      class: _normalizeClass({ error: _ctx.input.error }),
                                                      placeholder: _ctx.input.placeholder
                                                    }, null, 10, _hoisted_3)), [
                                                      [_vModelText, _ctx.data[_ctx.input.id]]
                                                    ])
                                                  : (_ctx.input.type.includes('date'))
                                                    ? (_openBlock(), _createElementBlock("input", {
                                                        key: 4,
                                                        type: "text",
                                                        value: _ctx.dateFilter(_ctx.data[_ctx.input.id], _ctx.input.type),
                                                        "data-id": _ctx.input.id,
                                                        class: _normalizeClass({ error: _ctx.input.error }),
                                                        placeholder: _ctx.input.placeholder,
                                                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args)))
                                                      }, null, 10, _hoisted_4))
                                                    : (_ctx.input.type === 'number')
                                                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                          key: 5,
                                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                                                          onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onlyNumber && _ctx.onlyNumber(...args))),
                                                          type: _ctx.input.type,
                                                          class: _normalizeClass({ error: _ctx.input.error }),
                                                          placeholder: _ctx.input.placeholder
                                                        }, null, 42, _hoisted_5)), [
                                                          [_vModelDynamic, _ctx.data[_ctx.input.id]]
                                                        ])
                                                      : (_ctx.input.mask)
                                                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                            key: 6,
                                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                                                            type: _ctx.input.type,
                                                            class: _normalizeClass({ error: _ctx.input.error }),
                                                            placeholder: _ctx.input.placeholder
                                                          }, null, 10, _hoisted_6)), [
                                                            [_directive_mask, _ctx.input.mask],
                                                            [_vModelDynamic, _ctx.data[_ctx.input.id]]
                                                          ])
                                                        : (_ctx.input.drop)
                                                          ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                                              key: 7,
                                                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input))),
                                                              onKeyup: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.inputKeyUp && _ctx.inputKeyUp(...args))),
                                                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                                                              type: _ctx.input.type,
                                                              class: _normalizeClass(["dropping", { error: _ctx.input.error, active: _ctx.input.showDrop }]),
                                                              placeholder: _ctx.input.placeholder
                                                            }, null, 42, _hoisted_7)), [
                                                              [_vModelDynamic, _ctx.data[_ctx.input.id]]
                                                            ])
                                                          : _withDirectives((_openBlock(), _createElementBlock("input", {
                                                              key: 8,
                                                              onKeyup: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.inputKeyUp && _ctx.inputKeyUp(...args))),
                                                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                                                              type: _ctx.input.type,
                                                              placeholder: _ctx.input.placeholder,
                                                              class: _normalizeClass({ error: _ctx.input.error })
                                                            }, null, 42, _hoisted_8)), [
                                                              [_vModelDynamic, _ctx.data[_ctx.input.id]]
                                                            ]),
                                            (_ctx.input.drop && !_ctx.input.disabled)
                                              ? (_openBlock(), _createBlock(_component_down_arrow_icon, {
                                                  key: 9,
                                                  class: _normalizeClass({ active: _ctx.input.showDrop }),
                                                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input)))
                                                }, null, 8, ["class"]))
                                              : _createCommentVNode("", true),
                                            _createVNode(_Transition, { name: "fade" }, {
                                              default: _withCtx(() => [
                                                (_ctx.input.drop && _ctx.input.showDrop)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                      _createElementVNode("ul", null, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.drop_data, (element) => {
                                                          return (_openBlock(), _createElementBlock("li", {
                                                            class: "flex items-center cursor",
                                                            key: element._id,
                                                            onClick: ($event: any) => (_ctx.$emit('selectDropElement', element, _ctx.input))
                                                          }, _toDisplayString(_ctx.dropElementName(element)), 9, _hoisted_10))
                                                        }), 128))
                                                      ])
                                                    ]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 1
                                            })
                                          ], 4))
                                        : _createCommentVNode("", true)
}