
import { defineComponent, PropType } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { Modal } from "@/interfaces/modal/modal.dto";
import { checkFileSize, checkImageFiles } from "@/utils/files";
import { useAPI } from "@/use";
import ModalElement from "./ModalElement.vue";

export default defineComponent({
  name: 'ModalInputs',
  props: {
    action: {
      type: String,
      required: true
    },
    module: {
      type: Object as PropType<Modal.Module>,
      required: true
    },
  },
  methods: {
    uploadFile({ input, ref }: { input: Modal.Input, ref: any }) {
      if (!checkFileSize(ref.files)) return;
      if (!checkImageFiles(ref.files)) return;
      this.module.data.file = ref.files[0];
      this.$store.commit("createNotification", {
        status: "success",
        message: 'Изображение загружено!',
      });
    },
    uploadFiles({ input, ref }: { input: Modal.Input, ref: any }) {
      if (!checkFileSize(ref.files)) return;
      this.module.data.files = ref.files;
      this.$store.commit("createNotification", {
        status: "success",
        message: 'Файлы прикреплены!',
      });
    },
    inputKeyUp({ event, input }: { event: KeyboardEvent, input: Modal.Input }) {
      if (event.key === 'Tab') {
        this.module.inputs.forEach(i => i.showDrop = false);
        if (input.drop || input.dropBox) input.showDrop = true;
        return;
      }
      if (input.drop) {
        this.inputDropKey(input);
      }
    },
    inputDropKey(input: Modal.Input)  {
      // Контекстный поиск
    },
    async selectDropElement(element: dynamicsObject, input: Modal.Input) {
      this.module.data[input.id] = element.name;
      this.module.data[input.id + '_id'] = element._id;
      input.showDrop = false;
      
      this.requestsModule(element, input);
    },
    async requestsModule(element: dynamicsObject, input: Modal.Input) {
      if (input.id === 'channel' && this.module.id === 'promocode-create') {
        this.module.data.tariff_id = this.module.data.tariff = '';
        const tariffs = this.module.inputs.find(i => i.id === 'tariff');
        if (!tariffs) return;
        
        const result = await useAPI().tariff.getAllMethod({ channel: element._id });
        tariffs.drop_data = result.data;
      }
      if (input.id === 'tariff' && this.module.id === 'channel-tariff') {
        const keys = ['disposable', 'duration', 'price', 'referral', 'double_price', 'visibility', '_id', 'name'];
        
        if (!element._id) {
          for (const key of keys)
            if (this.module.data[key] !== true && this.module.data[key] !== false)
              this.module.data[key] = '';
        } else {
          for (const key of keys)
            this.module.data[key] = element[key];
        }
        
        if (element?.referral?.status && element._id) {
          this.module.data.referral__status = element.referral.status;
          this.module.data.referral__days = element.referral.days;
          this.module.data.referral__link = element.referral.link;
          this.module.data.referral__reusable = element.referral.reusable;
          this.module.inputs.filter(e => e.id.includes('referral__')).forEach(e => {
            e.show = true;
            if (e.id === 'referral__days') e.required = true;
          });
        } else {
          this.module.data.referral__status = false;
          this.module.data.referral__days = '';
          this.module.data.referral__link = '';
          this.module.data.referral__reusable = false;
          this.module.inputs.filter(e => ['referral__days', 'referral__link', 'referral__reusable'].includes(e.id)).forEach(e => {
            e.show = false;
            e.required = false;
          });
        }
        if (element?.double_price?.status && element._id) {
          this.module.data.double_price__status = element.double_price.status;
          this.module.data.double_price__price = element.double_price.price;
          this.module.data.double_price__name = element.double_price.name;
          this.module.inputs.filter(e => e.id.includes('double_price__')).forEach(e => {
            e.show = true;
            if (['double_price__price'].includes(e.id)) e.required = true;
          });
        } else {
          this.module.data.double_price__status = false;
          this.module.data.double_price__price = '';
          this.module.inputs.filter(e => ['double_price__price', 'double_price__name'].includes(e.id)).forEach(e => {
            e.show = false;
            e.required = false;
          });
        }
      }
    },
    toggleShowData(key: string) {
      const input = this.module.inputs.find(i => i.id === key);
      if (!input) return;

      input.show = this.module.data.required[key];
    },
    toggleDrop(input: Modal.Input) {
      this.module.inputs.forEach(i => {
        if (i.id !== input.id) i.showDrop = false;
      });
      input.showDrop = !input.showDrop;
      if (input.showDrop) {
        this.jq("body").bind("click", (e) => {
          if (
            this.jq(e.target).closest(".drop").length === 0 &&
            this.jq(e.target).siblings(".drop").length === 0
          )
            input.showDrop = false;
        });
      } else {
        this.jq("body").unbind("click");
      }
    },
    toggleSwitcher(switcher: boolean, id: string) {
      this.module.inputs.filter(i => i[id]).forEach(i => {
        i.show = switcher;
        if (i.id === 'referral__days' || i.id === 'double_price__price') i.required = switcher;
      });
    }
  },
  components: {
    ModalElement
  }
})
