import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4174a02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inputs grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_element = _resolveComponent("modal-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.inputs, (input) => {
      return (_openBlock(), _createBlock(_component_modal_element, {
        key: input.id,
        data: _ctx.module.data,
        input: input,
        onUploadFile: _ctx.uploadFile,
        onUploadFiles: _ctx.uploadFiles,
        onToggleDrop: _ctx.toggleDrop,
        onInputKeyUp: _ctx.inputKeyUp,
        onToggleShowData: _ctx.toggleShowData,
        onSelectDropElement: _ctx.selectDropElement,
        onToggleSwitcher: _ctx.toggleSwitcher
      }, null, 8, ["data", "input", "onUploadFile", "onUploadFiles", "onToggleDrop", "onInputKeyUp", "onToggleShowData", "onSelectDropElement", "onToggleSwitcher"]))
    }), 128))
  ]))
}